import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { contentBlockSecondaryContainer } from './index.module.scss'

const ContentBlockSecondary = props => (
  <div className={`${contentBlockSecondaryContainer} ${props.media ? '' : 'full-width'}`}>
    <div className="content">
      {props.sectionIcon && <div className="kicker-icon">{props.sectionIcon}</div>}
      {props.kicker && <span className="kicker">{props.kicker}</span>}
      <h1 className="headline">{props.headline}</h1>
      <span className="description">{props.description}</span>

      {props.actionLink &&
        (props.actionLinkIsExternal ? (
          <a className="linkBtn primary" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
            {props.actionLinkText}
          </a>
        ) : (
          <Link className="linkBtn primary" to={props.actionLink}>
            {props.actionLinkText}
          </Link>
        ))}
    </div>
    <div className="item-container">
      {props.item_01 || null}
      {props.item_02 || null}
      {props.item_03 || null}
    </div>
  </div>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBlockAlternating.defaultProps = {
//   isExternalLink: false,
// };

export default ContentBlockSecondary
