import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { contentBannerContainer } from './index.module.scss'

const ContentBanner = props => (
  <div className={`${contentBannerContainer} ${props.media ? '' : 'full-width'}`}>
    <div className={`inner-wrapper ${props.reverse ? 'reverse' : ''}`}>
      <div className="content">
        <h1 className="headline">{props.headline}</h1>
        <span className="description">{props.description}</span>

        {props.actionLink &&
          (props.actionLinkIsExternal ? (
            <a className="linkBtn primary" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
              {props.actionLinkText}
            </a>
          ) : (
            <Link className="linkBtn primary" to={props.actionLink}>
              {props.actionLinkText}
            </Link>
          ))}
      </div>
      {props.media && <div className="media-container">{props.media}</div>}
    </div>
  </div>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBlockAlternating.defaultProps = {
//   isExternalLink: false,
// };

export default ContentBanner
