import React from 'react'
// import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../container/main'
import SEO from '../../components/seo'

import Container from '../../components/container'
import ContentHeroNew from '../../components/content-hero-new'
import ContentBlockLarge from '../../components/content-block-large'
import ContentBlockSmall from '../../components/content-block-small'
import ContentBlockSecondary from '../../components/content-block-secondary'
import ContentBanner from '../../components/content-banner'
import ContentInlineNav from '../../components/content-inline-nav'

import BasaasWorkplaceIcon from '../../assets/ic-basaas-workplace.svg'
import BasaasTasksIcon from '../../assets/ic-basaas-tasks.svg'
import BasaasExtensionIcon from '../../assets/ic-basaas-extension.svg'

// import IconAllAppsOneSolution from '../../assets/feature-icons/all-apps-one-solution.png'
// import IconMultiDevice from '../../assets/feature-icons/multi-device.png'
// import IconPasswordManager from '../../assets/feature-icons/password-manager.png'
// import IconOneTaskSolution from '../../assets/feature-icons/one-task-solution.png'
// import IconWorkAcrossAppsTeams from '../../assets/feature-icons/work-across-apps-teams.png'
// import IconOptimizeOperationalTasks from '../../assets/feature-icons/optimize-operational-tasks.png'
// import IconGPDRCompliant from '../../assets/feature-icons/gdpr-compliant.png'
// import IconProvenEnterpriseSecurity from '../../assets/feature-icons/proven-enterprise-security.png'
import IconTrust from '../../assets/images/trust-icon.svg'
import IconExtension from '../../assets/images/ic_extension.svg'

const IndexPage = ({ data }) => (
  <MainContainer>
    <SEO title="A new era for digital work" />
    <Container>
      <ContentHeroNew
        // kicker="Enterprise Productivity Hub"
        headline="A new era for digital work"
        description="Everything productive people need now runs in the browser"
        // actionLinkText="Get started – It's free!"
        // actionLinkIsExternal
        // actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup&utm_content=home"
        // media={<StaticImage src="../../assets/images/home-hero.png" alt="Enterprise Productivity Hub" placeholder="blurred" />}
      />
    </Container>
    <Container style={{ zIndex: '2', marginBottom: '-45px' }}>
      <StaticImage
        src="../../assets/images/a-new-era-for-digital-work.png"
        alt="Everything productive people need now runs in the browser"
        placeholder="blurred"
        style={{ display: 'block', maxWidth: '480px', margin: '0 auto' }}
      />
    </Container>
    {/* BLOCK EXTENSION  */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        sectionIcon={<BasaasExtensionIcon />}
        kicker="Boost your productivity in Google Chrome"
        headline="Work across apps everywhere"
        description="Benefit from the new Basaas Chrome Extension and get all your notifications in one place. Enhance the workplace in your browser with a unified view on all your daily to dos and events and switch between all your apps quickly. "
        actionLinkText="Try Basaas Chrome Extension today"
        actionLink="https://chrome.google.com/webstore/detail/basaas-extension/dfnefpadkibojlglpobnjbphlaojobfg?utm_source=basaas-com&utm_medium=content-block&utm_campaign=extension&utm_content=lp-new-era"
        actionLinkIsExternal
        actionLinkInNewWindow
        media={<StaticImage src="../../assets/images/extension-hero.png" alt="Optimize your app management" placeholder="blurred" />}
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Your sidebar in the browser"
          description="Find your sidebar on every webpage. Launch personal or team apps right away and get notifications for upcoming events from your apps."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/your-sidebar-in-the-browser.png" alt="All your apps in one solution" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Access your apps everywhere"
          description="Find apps easily with the built-in search and open them via our new Chrome extension pop-up to really work across all your apps."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/access-your-apps-everywhere.png" alt="Work remote on any device" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="All you need in a new tab"
          description="Open a new tab and see “My Day”. It shows  all your events for today and tasks that you need to focus on."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/all-you-need-in-a-new-tab.png" alt="Built-in password manager" placeholder="blurred" />}
        />
      </Container>
    </Container>
    {/* BLOCK TASKS  */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        sectionIcon={<BasaasTasksIcon />}
        kicker="Organize tasks across apps and beyond teams"
        headline="Unite all tasks of your favorite apps in one place"
        description="Basaas Tasks offers everything you need to keep track of all your daily tasks. Connect all your tasks tools and bring all tasks and reminders into one well-organized solution. Get cross app overview of your workflows and be more productive. With Basaas you can work from anywhere and on any device."
        actionLinkText="Open Tasks"
        actionLink="https://tasks.basaas.com"
        actionLinkIsExternal
        actionLinkInNewWindow
        media={<StaticImage src="../../assets/images/home-tasks.png" alt="Organize tasks across apps and beyond teams" placeholder="blurred" />}
        reverse
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Manage your tasks across all apps"
          description="Connect your tasks tools and bring all tasks and reminders into one central and well-organized solution. View and edit tasks of your task tools you are already using in one place. Get cross app overview of your workflows and be more productive."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/manage-tasks-across-all-apps.png" alt="One solution for all tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="All your projects in one solution"
          description="The ability to manage projects is one of the most crucial skills for any organization. With Basaas Tasks, you can easily create projects to bring your team’s work together. Projects helps you to gain transparency to your team while improving collaboration."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/manage-all-projects-in-one-solution.png" alt="Work across apps and teams" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Create tasks everywhere"
          description="Create tasks anywhere in any browser solution and save them directly to your favorite app. Sometimes, for example, you get an email that triggers a new to-do. Just right-click in your email app and create a task in Basaas or directly in your favorite task management."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/create-tasks-everywhere.png" alt="Optimize operational tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Assign tasks to teammates"
          description="Share your workload and assign tasks to responsible teammates. You can simply delegate tasks to colleagues. Keep always the overview of your delegated tasks and get notified about completed tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/assign-tasks-to-teammates.png" alt="One solution for all tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="See everything you need to do today"
          description="Stay perfectly organized and focus on today‘s most important tasks. Basaas offers you a perfect overview of today’s agenda so you can prioritize your workday and focus your energy in the right direction."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/organize-your-daily-agenda.png" alt="Optimize operational tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Organize your remote working teams"
          description="Remote working has never been more popular. One challenge is to manage all colleagues working remotely while keeping productivity high. Basaas provides the basis for successful remote working, because every colleague has always a perfect overview of all relevant tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/work-across-apps-teams.png" alt="Organize your remote working teams" placeholder="blurred" />}
        />
      </Container>
    </Container>

    {/* BLOCK WORKPLACE  */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        sectionIcon={<BasaasWorkplaceIcon />}
        kicker="Your workplace on any device"
        headline="A powerful workplace in your browser"
        description="Quickly access and really work across all your apps with your workplace in your browser. With new features like “My Day” or a new navigation, our mission is to make your workday easier.  And the best of all, you can use Basaas anywhere and on any device."
        actionLinkText="Open Workplace"
        actionLink="https://workplace.basaas.com"
        actionLinkIsExternal
        actionLinkInNewWindow
        media={<StaticImage src="../../assets/images/home-workplace.png" alt="Your workplace on any device" placeholder="blurred" />}
        reverse
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Everywhere on any device"
          description="Basaas lets you work from anywhere and on any device. Responsive design makes Basaas perfect for flexible use by smartphones and tablets. With Basaas you are perfectly prepared for remote work."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/multi-device.png" alt="One solution for all tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Switch between Basaas Tools"
          description="With the new navigation you can easily switch to all areas in Basaas. Whether you want to go to Tasks, your Workplace or even manage your integrations."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/switch-between-basaas-tools-easily.png" alt="Work across apps and teams" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Stay up to date with “My Day”"
          description="Stay perfectly organized with “My Day”. View your next appointments and your handpicked focus tasks. With just one click you can join your next online meeting or complete your next task."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/see-everything-you-need-to-do-today.png" alt="Optimize operational tasks" placeholder="blurred" />}
        />
      </Container>
    </Container>

    <ContentBanner
      headline="Productivity superpowers for your browser"
      description="Check out the Basaas Chrome Extension"
      actionLinkText="Try Basaas Chrome Extension today"
      actionLinkIsExternal
      actionLinkInNewWindow
      actionLink="https://chrome.google.com/webstore/detail/basaas-extension/dfnefpadkibojlglpobnjbphlaojobfg?utm_source=basaas-com&utm_medium=banner&utm_campaign=extension&utm_content=lp-new-era"
      // media={<StaticImage src="../../assets/images/app-icons.svg" alt="Optimize your app management" placeholder="blurred" />}
    />
  </MainContainer>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
